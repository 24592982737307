import React, { useState, useEffect, useCallback } from 'react';

let logoutTimer;

const AuthContext = React.createContext({
  token: '',
  isLoggedIn: false,
  apiKey: '',
  login: (token) => {},
  logout: () => {}
});

const getStoredTokenData = () => {
  let token = localStorage.getItem('token');
  const expTime = localStorage.getItem('tokenExpirationDate');
  const remainingTime = +expTime - new Date().getTime();
  if (!expTime || remainingTime <= 0) {
    localStorage.removeItem('token');
    localStorage.removeItem('tokenExpirationDate');
    return null;
  }
  return { token, remainingTime };
};

export const AuthContextProvider = (props) => {
  let initialToken;
  const tokenData = getStoredTokenData();
  if (tokenData) {
    initialToken = tokenData.token;
  }
  const [token, setToken] = useState(initialToken);

  const userLoggedIn = !!token;
  const apiKey = 'AIzaSyCqLTUvSbVo1VBOjfwudmZxC2np1GKxOFo';

  const logoutHandler = useCallback(() => {
    setToken(null);
    localStorage.removeItem('token');
    localStorage.removeItem('tokenExpirationDate');
    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }
  }, []);

  const loginHandler = (token, expirationTimeMS) => {
    setToken(token);
    localStorage.setItem('token', token);
    const expirationDate = new Date().getTime() + expirationTimeMS;
    localStorage.setItem('tokenExpirationDate', expirationDate);
    logoutTimer = setTimeout(logoutHandler, expirationTimeMS);
  };

  useEffect(() => {
    if (tokenData) {
      logoutTimer = setTimeout(logoutHandler, tokenData.remainingTime);
    }
  }, [tokenData, logoutHandler]);

  const contextValue = {
    token,
    isLoggedIn: userLoggedIn,
    apiKey,
    login: loginHandler,
    logout: logoutHandler
  };

  return <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>;
};

export default AuthContext;
