import { useRef, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import AuthContext from '../../store/auth-context';

import classes from './ProfileForm.module.css';

const ProfileForm = () => {
  const newPasswordInput = useRef();
  const authCtx = useContext(AuthContext);
  const history = useHistory();

  const submitHandler = () => {
    const enteredNewPassword = newPasswordInput.current.value;
    fetch(`https://identitytoolkit.googleapis.com/v1/accounts:update?key=${authCtx.apiKey}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        idToken: authCtx.token,
        password: enteredNewPassword,
        returnSecureToken: false
      })
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          return res.json().then((data) => {
            let errMsg = 'Failed to update password';
            if (data && data.error && data.error.message) {
              errMsg = data.error.message;
            }
            throw new Error(errMsg);
          });
        }
      })
      .then((data) => {
        history.replace('/');
      })
      .catch((err) => {
        alert(err.message);
      });
  };
  return (
    <form className={classes.form} onSubmit={submitHandler}>
      <div className={classes.control}>
        <label htmlFor="new-password">New Password</label>
        <input ref={newPasswordInput} minLength="6" type="password" id="new-password" />
      </div>
      <div className={classes.action}>
        <button>Change Password</button>
      </div>
    </form>
  );
};

export default ProfileForm;
